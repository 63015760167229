import { Theme } from 'theme-ui';

const heading = {
  fontFamily: 'heading',
  fontWeight: 'heading',
};

const caption = {
  color: 'black',
  fontFamily: 'body',
};

const defaultText = {
  color: 'text',
  fontFamily: 'body',
  fontWeight: 'body',
  lineHeight: 'body',
  fontSize: '20px',
};

const theme: Theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'HelveticaNeue, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.6,
  },
  colors: {
    // Wireframe colors
    black: 'rgb(0,0,0)',
    coolGray: 'rgb(166,168,168)',
    darkGray: 'rgb(22,21,32)',
    white: 'rgb(255,255,255)',
    highlight: 'rgb(235,234,242)',
    dark: 'rgb(16,15,23)', // This is also the text color
    paleGray: 'rgb(247,248,254)',
    lightBlueGray: 'rgb(209,209,215)',
    serviceSuccess: 'rgb(88,206,109)',
    paleGrayTwo: 'rgb(241,241,248)',

    // Ui Lib colors
    topBarGray: 'rgb(234,234,241)',
    navBarGray: 'rgb(86, 87, 89)',
    heading: 'rgb(20,20,29)',
    brand: '#1eb4e6',
    spinnerBgGray: '#c8c8c8',

    text: 'rgb(16,15,23)',
    textGray: 'rgb(92,92,96)',
    background: 'rgb(255,255,255)',
    primary: 'rgb(74,124,235)',
    secondary: 'rgb(255,100,0)',
    danger: 'rgb(255,20,46)',
    dangerLight: 'rgb(255,138,151)',
    dangerLightest: 'rgba(253,72,64,.15)',
    gray: 'rgb(242,243,249)',
    grayDarker: 'rgb(234,234,241)',
    environments: {
      development: {
        backgroundColor: 'rgb(0,138,62)',
        color: 'rgb(236,255,250)',
      },
      stage: {
        backgroundColor: 'rgb(1,84,174)',
        color: 'rgb(230,237,255)',
      },
      production: {
        backgroundColor: 'rgb(165,1,1)',
        color: 'rgb(255,230,230)',
      },
    },

    modes: {
      vet: {
        background: 'rgba(22,21,32)',
        text: 'rgb(250,250,250)',
        secondary: 'rgb(255,100,0)',
        primary: 'rgb(30,180,230)',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 2,
    },
    p: {
      ...defaultText,
    },
    a: {
      color: 'darkGray',
      textDecoration: 'none',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
    buttons: {
      primary: {
        color: 'rgb(255,255,255)',
        bg: 'primary',
        '&:hover': {
          bg: 'rgb(46,89,186)',
        },
        borderColor: 'transparent',
      },
      secondary: {
        color: 'text',
        bg: 'rgb(255,255,255)',
        borderColor: 'grayDarker',
      },
      tertiary: {
        color: 'primary',
        bg: 'transparent',
        borderColor: 'transparent',
      },
      link: {
        color: 'primary',
        bg: 'transparent',
        borderColor: 'transparent',
        p: 0,
        border: 'none',
      },
    },
  },

  text: {
    default: {
      ...defaultText,
    },
    muted: {
      ...defaultText,
      opacity: 0.6,
    },
    bold: {
      ...defaultText,
      fontWeight: 'bold',
    },
    small: {
      ...defaultText,
      fontSize: '16px',
      lineHeight: 1.5,
    },
    heading: {
      h1: {
        ...heading,
        fontSize: '52px',
        lineHeight: 1.23,
        letterSpacing: '-1.11px',
        color: 'rgb(20,20,29)',
      },
      h2: {
        ...heading,
        fontSize: '48px',
        lineHeight: 1.17,
        letterSpacing: '-1px',
        color: 'rgb(3,3,3)',
      },
      h3: {
        ...heading,
        fontSize: '34px',
        lineHeight: 1.41,
        letterSpacing: '-0.71px',
        color: 'rgb(3,3,3)',
      },
      h4: {
        ...heading,
        fontSize: '24px',
        lineHeight: 1.92,
        letterSpacing: 0,
        color: 'black',
      },
    },

    caption: {
      big: {
        ...caption,
        fontSize: '28px',
        lineHeight: 1.64,
        fontWeight: 500,
        letterSpacing: '0.44px',
      },
      small: {
        ...caption,
        fontSize: '24px',
        lineHeight: 1.5,
        fontWeight: 'body',
      },
      mini: {
        ...caption,
        fontSize: '20px',
        lineHeight: 1.4,
        fontWeight: 'bold',
      },
    },
  },
};

export default theme;
